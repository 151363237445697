import Parse from 'parse'

const APP_ID = "jdgAoBJbbxGC2Q4yqTv276ea0lTLWf5tAAHtRWtR";
const JS_KEY = "XbS8xPZ5EenNjGlyBglbgrow9ZPWqp0UPhXrjn2a";

export const connectToServer = function () {

    Parse.serverURL = 'https://parseapi.back4app.com/';
    Parse.initialize(APP_ID,JS_KEY);


};