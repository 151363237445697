import { AppBar, createMuiTheme, Grid, makeStyles, MuiThemeProvider, responsiveFontSizes, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Carousel } from 'react-bootstrap';
import rinsonChory from '../photo/rinsonChory.jpeg';
import star from '../photo/star.jpg';
import fast from '../photo/fast.jpeg';
import Truck from '../photo/Truck.jpeg';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import LightSpeed from 'react-reveal/LightSpeed';

const useStyle = makeStyles(theme => ({

    image: {
        minWidth: '100vw',
        minHeight: '100%',
        backgroundImage: `url(${fast})`,
        backgroundSize: 'cover',
        display: 'flex'
    },image2: {
        minWidth: '100vw',
        minHeight: '100%',
        backgroundImage: `url(${Truck})`,
        backgroundSize: 'cover',
        display: 'flex'
    },image3: {
        minWidth: '100vw',
        minHeight: '100%',
        backgroundImage: `url(${rinsonChory})`,
        backgroundSize: 'cover',
        display: 'flex'
    },

    widetext: {
        color: '#8793ca',
        lineHeight: 1,
        fontFamily: 'allerBd',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5rem',
        },      
        [theme.breakpoints.up('md')]: {
            fontSize: '6rem',
        },
    },

    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '100%',
        height: '100vh',
        display: 'flex'

    },

    bigtext: {
        color: "white",
        lineHeight: 1,
        fontFamily: 'allerBd',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '6rem',
        },
    },

    smalltext: {
        color: 'white',
        opacity: '87%',
        fontFamily: 'AllerLtlt',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
    },

    parent: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70,
        },

        height: '100vh',
        width: '100%'

    }
}))

const Carousels = () => {

    const classes = useStyle();

    return (
        <Grid>
            <Carousel controls={false} pause={false}>
                <Carousel.Item interval={4000}>
                    <Grid container direction="column" justify="center" className={classes.image} >
                        <div className={classes.background} >
                            <Grid container direction="column" justify="center" className={classes.parent} >
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <Fade bottom cascade>
                                            International 
                                        </Fade>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <Fade bottom cascade>
                                            Trade
                                        </Fade>
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                        </div>
                    </Grid>
                </Carousel.Item>

                <Carousel.Item interval={4000}>
                    <Grid container direction="column" alignItems="center" className={classes.image2}>
                        <div className={classes.background}>
                            <Grid container direction="column" justify="center" className={classes.parent}  >
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <LightSpeed left  cascade>
                                            Domestic
                                        </LightSpeed>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <LightSpeed left  cascade>
                                            Logistics
                                        </LightSpeed>
                                    </Typography>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </Grid>
                </Carousel.Item>

                <Carousel.Item interval={4000}>
                    <Grid container direction="column" alignItems="center" className={classes.image3}>
                        <div className={classes.background}>
                            <Grid container direction="column" justify="center" className={classes.parent} >
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <Flip  bottom cascade>
                                            Shipping
                                        </Flip>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.bigtext}>
                                        <Flip  bottom cascade>
                                            Solutions
                                        </Flip>
                                    </Typography>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </Grid>
                </Carousel.Item>
            </Carousel>
        </Grid>
    );
}

export default Carousels;

