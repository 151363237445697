import { Divider, Grid, Hidden, Icon, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import { Link, smooth } from 'react-scroll';
import ResponsiveEmbed from 'react-responsive-embed';
import history from '../history';


const useStyle = makeStyles(theme => ({

    background: {
        backgroundColor: '#e0e3f1',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    icon: {
        color: 'primary',
    },
    headtext: {
        fontSize: 22,
        fonsFamily: 'AllerLt',
        color: 'primary',

    },
    text: {
        color: 'primary',
        fontSize: 14,
        fontFamily: 'AllerLt',
        cursor: 'pointer',
        marginLeft: 10,
        marginTop: 10,
    },
    quicklinktext: {
        color: 'primary',
        fontSize: 14,
        fontFamily: 'AllerLt',
        cursor: 'pointer',
        marginTop: 10,

    },
    iframe: {
        padding: 20
    },

    footertext: {
        color: 'primary',
        opacity: '67%',
        fontSize: 12,
        padding: 20,
        fontFamily: 'AllerLt',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },

    },
    divider: {
        margin: '0px 20px 0px 20px',
        opacity: '30%',
        backgroundColor: 'white',
        marginTop: 24
    },
    parent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        },
    },
    class: {
        [theme.breakpoints.down('sm')]: {
            justify: 'center'
        },

    }



}))

const Footer = (props) => {

    const classes = useStyle();

    const handleClick = () => {
        if (props.product)
            history.push("/")
    }

    return (
        <Grid container direction="column" justify="center" className={classes.background}>
            <Grid item style={{ width: '100%' }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 20 }}>
                        <ResponsiveEmbed width="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14683.478204445106!2d70.1402674!3d23.0652436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20b79a48fc4c1927!2sSimorg%20India%20LLP!5e0!3m2!1sen!2sin!4v1622732862180!5m2!1sen!2sin"
                            ratio="16:9" />
                    </Grid>


                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.parent}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Grid container direction="column" spacing={5} >
                                            <Grid item >
                                                <Grid container wrap="nowrap">
                                                    <Grid item>
                                                        <Icon className={classes.icon}>
                                                            <LocationOnIcon />
                                                        </Icon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.text}>
                                                            <b>Reg. Address:</b><br />
                                                            21-22, K.K Park,<br />
                                                            Shastri road,<br />
                                                            Bardoli,<br />
                                                            Surat,<br />
                                                            GUJARAT, INDIA. 394601
                                                </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item >
                                                <Grid container wrap="nowrap">
                                                    <Grid item>
                                                        <Icon className={classes.icon}>
                                                            <LocationOnIcon />
                                                        </Icon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.text}>
                                                            <b>Office Address:</b><br />
                                                            Office no. 9B, Second floor,<br />
                                                            PD Plaza, Plot No. 3, Sector 9A,<br />
                                                            Rabindranath Tagore Rd,<br />
                                                            Gandhidham,<br />
                                                            GUJARAT 370201
                                                </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item >
                                                <Grid container wrap="nowrap">
                                                    <Grid item>
                                                        <Icon className={classes.icon}>
                                                            <CallIcon />
                                                        </Icon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.text}>
                                                            +91-96018 15858, +91-9898605275, +91-9484792786
                                                </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item >
                                                <Grid container wrap="nowrap">
                                                    <Grid item>
                                                        <Icon className={classes.icon}>
                                                            <MailIcon />
                                                        </Icon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.text}>
                                                            simorgindia@gmail.com, info@simorgindia.com, exports@simrogindia.com
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.parent}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.headtext}>
                                            Quick Links
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginTop: 15 }}>
                                        {
                                            props.product ?
                                                <Typography className={classes.quicklinktext} onClick={handleClick} >
                                                    About Us
                                            </Typography>
                                                :
                                                <Link to="aboutus" smooth={true} duration={500}>
                                                    <Typography className={classes.quicklinktext}>
                                                        About Us
                                            </Typography>
                                                </Link>
                                        }

                                    </Grid>

                                    <Grid item>
                                        {
                                            props.product ?
                                                <Typography className={classes.quicklinktext} onClick={handleClick} >
                                                    Products
                                            </Typography>
                                                :
                                                <Link to="productscreen" smooth={true} duration={500}>
                                                    <Typography className={classes.quicklinktext} >
                                                        Products
                                            </Typography>
                                                </Link>
                                        }
                                    </Grid>


                                    <Grid item>
                                        {
                                            props.product ?
                                                <Typography style={{ marginBottom: 30 }} className={classes.quicklinktext} onClick={handleClick} >
                                                    Services
                                            </Typography>
                                                :
                                                <Link to="services" smooth={true} duration={500}>
                                                    <Typography style={{ marginBottom: 30 }} className={classes.quicklinktext}>
                                                        Services
                                            </Typography>
                                                </Link>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item >
                <Divider className={classes.divider} />
            </Grid>

            <Hidden smDown>
                <Grid item>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography className={classes.footertext}>
                                Copyright © 2021. All rights reserved.
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.footertext}>
                                Powered By: <a style={{ color: 'black', cursor: 'pointer' }} target="_blank" href="http://mnjapps.com/">MNJ tech.</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>


            <Hidden mdUp>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <Typography className={classes.footertext}>
                                Copyright © 2021. All rights reserved.
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.footertext}>
                                Powered By: <a style={{ color: 'black', cursor: 'pointer' }} target="_blank" href="http://mnjapps.com/">MNJ tech.</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    );
}

export default Footer;










