import { Divider, Grid, Hidden, Icon, IconButton, Input, InputBase, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
import map from '../photo/map.jpg';
import Fade from 'react-reveal/Fade'
import CustomSnack from '../utils/CustomSnack';
import { validateEmail, validateMobile } from '../utils/Validations';
import Parse from 'parse'

const useStyle = makeStyles(theme => ({

    parent: {
        background: '#e0e3f1',
        width: '100vw',
        minHeight: '100vh',
        [theme.breakpoints.up("md")]: {
            padding: 75
        }, [theme.breakpoints.down("sm")]: {
            padding: 40
        },
    },

    image: {
        backgroundImage: `url(${map})`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        opacity: '67%',
        padding: 30,
    },

    background: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        borderRadius: 20,
        overflow: 'hidden',
    },

    icon: {
        borderRadius: 50,
        color: '#2f2f2f',
        fontWeight: 'bold',
        width: 40,
        height: 40,
        cursor: 'pointer',
    },

    headtext: {
        marginTop: 24,
        [theme.breakpoints.down("sm")]: {
            fontSize: 30,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 40,
        },

        color: '#2f2f2f',
        fontFamily: 'allerBd'
    },

    text: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#2f2f2f',
        fontFamily: 'AllerLt',
        opacity: 1
    },

    textfield: {
        height: 50,
        border: '1px solid #252525',
        borderRadius: 10,
        marginTop: 10,
        overflow: 'hidden',
        backgroundColor: '#e0f7fa',
        paddingLeft: 20,
        fontFamily: 'AllerLt',
        fontWeight: 'bold'
    },

    button: {

        [theme.breakpoints.down("sm")]: {
            padding: '10px 80px 10px 80px',
        },
        [theme.breakpoints.up("md")]: {
            padding: '10px 110px 10px 110px',
        },
        backgroundColor: '#2f2f2f',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'allerBd',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop: 24,
        cursor: 'pointer',
        marginBottom: 50,
        '&:hover':{
            boxShadow: "1px 2px 10px black"
        }
    },btnDisable:{
        [theme.breakpoints.down("sm")]: {
            padding: '10px 80px 10px 80px',
        },
        [theme.breakpoints.up("md")]: {
            padding: '10px 110px 10px 110px',
        },
        backgroundColor: '#BDBDBD',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'allerBd',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop: 24,
        marginBottom: 50,
    },

    formtext: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
        },
        lineHeight: 1,
        color: '#2f2f2f',
        fontFamily: 'AllerLt',
        fontWeight: 'bold'
    },

    form: {
        marginTop: 20,
        width: '100%',
        height: '100%'
    }
}))

const ContactUs = () => {

    const classes = useStyle();

    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [mobile, setMobile] = React.useState("");
    const [snackContent, setSnackContent] = React.useState();
    const [variant, setVariant] = React.useState("error");
    const [btnDisable,setBtnDisable] = React.useState(false);

    const handleSubmit = () => {
        if (name.length === 0) {
            setSnackContent("Please enter Full Name");
            setVariant("error")
            return
        }

        if (!validateEmail(email)) {
            setSnackContent("Please enter valid Email");
            setVariant("error")
            return
        }

        if (!validateMobile(mobile)) {
            setSnackContent("Please enter valid Mobile");
            setVariant("error")
            return
        }
        setBtnDisable(true)
        const obj = new Parse.Object("Contact");
        obj.set("name",name);
        obj.set("email",email);
        obj.set("mobile",mobile);
        Parse.Object.saveAll(obj).then(res=>{
            setSnackContent("Thank you for reaching out to us. We will contact you as soon as possible.");
            setVariant("success")
            setEmail("")
            setName("")
            setMobile("")
        })
    }

    const handleChange = (e) => {
        if (e.target.id === "tf-name")
            setName(e.target.value)
        else if (e.target.id === "tf-email")
            setEmail(e.target.value)
        else if (e.target.id === "tf-mobile")
            setMobile(e.target.value)
    }

    return (
        <section id="contactus">
            <Grid container justify='center' className={classes.parent}>
                <CustomSnack
                    open={Boolean(snackContent)}
                    onClose={() => { setSnackContent(null) }}
                    message={snackContent}
                    variant={variant} />
                <Grid item className={classes.background}>
                    <Grid container spacing={0}>
                        <Hidden smDown>
                            <Grid item xs={4}>
                                <Grid container direction='column' alignItems='center' className={classes.image}>

                                </Grid>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={8}>
                            <Fade bottom>
                                <Grid container justify='center'>
                                    <Grid item xs={12}>
                                        <Grid container direction='column' alignItems='center'>
                                            <Grid item>
                                                <Typography className={classes.headtext}>
                                                    GET IN TOUCH
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography align="center" className={classes.formtext}>
                                                    Leave your contact details<br />
                                            We will contact you as soon as possible
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ marginTop: 60, width: '100%', padding: 24 }}>
                                                <Grid conatiner>
                                                    <Grid item xs={12}>
                                                        <InputBase fullWidth
                                                        id="tf-name"
                                                            className={classes.textfield}
                                                            value={name}
                                                            onChange={handleChange}
                                                            placeholder="Enter Your Full Name" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputBase fullWidth
                                                        id="tf-email"
                                                            value={email}
                                                            onChange={handleChange}
                                                            className={classes.textfield}
                                                            placeholder='Enter Your Email' />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputBase
                                                            fullWidth
                                                            id="tf-mobile"
                                                            className={classes.textfield}
                                                            value={mobile}
                                                            onChange={handleChange}
                                                            placeholder='Enter Your Mobile Number' />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <Typography className={btnDisable?classes.btnDisable: classes.button} onClick={handleSubmit} >
                                                    SUBMIT
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>



                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </section>
    );
}

export default ContactUs;