import { Grid, Hidden, makeStyles, Typography, IconButton } from '@material-ui/core';
import React from 'react';
import Ship from '../photo/Ship.jpg';
import container1 from '../photo/container1.jpg';
import port from '../photo/port.jpg';
import storage from '../photo/storage.jpg';
import train from '../photo/train.jpg';
import port2 from '../photo/port2.jpg';
import Truck from '../photo/company_truck.jpg';
import Container from '../photo/Container.jpg';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.min.css';
import { ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';


const useStyle = makeStyles(theme => ({

    parent: {
        [theme.breakpoints.up('md')]: {
            minHeight: '100vh',
        },
        overflowX: 'hidden',
        background: '#e0e3f1',
    },

    headtext: {
        fontFamily: 'allerBd',
        fontSize: 100,
        color: "primary",
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: '4rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '6rem',
        },
    },

    image: {
        [theme.breakpoints.down('sm')]: {
            width: '40vh',
            height: '40vh',
        },
        [theme.breakpoints.up('md')]: {
            width: '89vh',
            height: '50vh',
        },
        width: '89vh',
        height: '50vh',
        backgroundSize: 'cover',
        borderRadius: 30,
        overflow: 'hidden',
    },

    textarea: {
        [theme.breakpoints.down('sm')]: {
            height: 85,
        },
        [theme.breakpoints.up('md')]: {
            height: 100,
        },

        width: '100%',
        backgroundColor: 'black',
        opacity: '87%',
        color: 'white',
    },

    text: {
        fontFamily: 'AllerLt'
    },

    swiper: {
        height: '100%',
    },
    swiperContainer: {
        width: "100%",
        marginTop: 70,
        marginBottom: 70
    }, desc: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
        },
        color: 'primary',
        fontFamily: 'AllerLt',
        fontWeight: 'bold',
        marginBottom: 36,
        marginTop: 24,
        marginLeft: 24,
        marginRight: 24
    },
}))


const ServiceScreen = () => {

    const classes = useStyle();
    const ref = React.useRef(null)

    React.useEffect(()=>{
        window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    },[])

    const smallparams = {
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
    }

    const params = {
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 50,
    }

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    }

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    }

    const handleKeyDown = (e)=>{
        if(e.keyCode === 37)
            goPrev()
        else if(e.keyCode === 39)
            goNext()
    }   

    const getSwiperContent = () => {
        return [
            <div className={classes.image} style={{ backgroundImage: `url(${Truck})`, }}>
                <Grid container style={{ height: "100%" }} alignItems="flex-end">
                    <Grid item xs={12}>
                        <div className={classes.textarea}>
                            <Grid container alignItems='center' justify="center" style={{ height: "100%" }} direction="column">
                                <Grid item>
                                    <Typography variant="h6" className={classes.text}>
                                        Road Transport
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

            </div>
            ,
            <div className={classes.image} style={{ backgroundImage: `url(${port})` }}>
                <Grid container style={{ height: "100%" }} alignItems="flex-end">
                    <Grid item xs={12}>
                        <div className={classes.textarea}>
                            <Grid container alignItems='center' justify="center" style={{ height: "100%" }} direction="column">
                                <Grid item>
                                    <Typography variant="h6" className={classes.text}>
                                        Ocean Freight
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

            </div>
            ,

            <div className={classes.image} style={{ backgroundImage: `url(${storage})`, }}>
                <Grid container style={{ height: "100%" }} alignItems="flex-end">
                    <Grid item xs={12}>
                        <div className={classes.textarea}>
                            <Grid container alignItems='center' justify="center" style={{ height: "100%" }} direction="column">
                                <Grid item>
                                    <Typography variant="h6" className={classes.text}>
                                        Godowns &amp; Storage Facilities
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

            </div>
            ,
            <div className={classes.image} style={{ backgroundImage: `url(${Ship})` }}>
                <Grid container style={{ height: "100%" }} alignItems="flex-end">
                    <Grid item xs={12}>
                        <div className={classes.textarea}>
                            <Grid container alignItems='center' justify="center" style={{ height: "100%" }} direction="column">
                                <Grid item>
                                    <Typography variant="h6" className={classes.text}>
                                        Shipping Services
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

            </div>

        ]
    }


    return (
        <section id="services">
            <Grid container direction="column" alignItems="center" className={classes.parent}>
                <Grid item>
                    <Typography className={classes.headtext}>
                        SERVICES
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container justify="center">
                        <Grid item x={12} md={8}>
                            <Typography align="center" className={classes.desc}>
                                What makes us different from others is the services we offer like we act as a single
                                entity between the importer and exporter offering services starting from source to
                                destination inclusive of procurement, logistics support, storage, customs clearance,
                                freight forwarding.
                                <br /><br />
                                Our services offer road transport, air freight and sea freight solutions for shipments
                                of any size.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className={classes.swiperContainer}>
                    <Hidden mdUp>
                        <Swiper {...smallparams} ref={ref} className={classes.swiper}>
                            {getSwiperContent()}
                        </Swiper>
                    </Hidden>
                    <Hidden smDown>
                        <Swiper {...params} ref={ref} className={classes.swiper}>
                            {getSwiperContent()}
                        </Swiper>
                    </Hidden>
                    <Grid container justify="center" style={{marginTop:12}}>
                        <Grid item>
                            <IconButton onClick={goPrev}>
                                <ChevronLeftRounded />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={goNext}>
                                <ChevronRightRounded />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
}

export default ServiceScreen;