import { Grid } from '@material-ui/core';
import React from 'react';
import AboutUs from './AboutUs';
import Carousels from './Carousels';
import ServiceScreen from './ServiceScreen';
import ProductsScreen from './ProductsScreen';
import ContactUs from './ContactUs';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../history';




const HomeScreen = () => {

  React.useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <Grid container style={{ overflowX: 'hidden' }}>
      <Grid item xs={12}>
        <Carousels />
      </Grid>
      <Grid item xs={12}>
        <AboutUs />
      </Grid>
      <Grid item xs={12}>
        <ServiceScreen />
      </Grid>
      <Grid item xs={12}>
        <ProductsScreen />
      </Grid>
      <Grid item xs={12}>
        <ContactUs />
      </Grid>
    </Grid>
  );
}

export default HomeScreen;