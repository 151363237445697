import { Grid, List, ListItem, ListItemIcon, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import CategoriesListItem from './CategoriesListItem';
import corn from '../photo/corn.jpg';
import coal from '../photo/coal.jpg';
import ship from '../photo/Ship.jpg';
import laboratory from '../photo/laboratory.jpg';



const categoriesList = [
    {
        image: corn, category: "Agriculture", subcategory: [
            "Rice", "Wheat", "Soyabean"
        ]
    },
    {
        image: coal, category: "Minerals & Metals", subcategory: [
            "Coal", "Zinc", "Aluminium", "Salt", "Copper","Manganese"
        ]
    },
    {
        image: laboratory , category: "Chemicals", subcategory: [
            "Ketonic Resin", "Solvent Based Dyes", "Resins", "Adhesives"
        ]
    },{
        image: ship, category: "Others", subcategory: [
            "PE Surface Protection Films", "Masking Tape","Medical Equipments"
        ]
    }]


const useStyle = makeStyles(theme => ({

    background: {
        backgroundColor: '#e0e3f1',
        paddingTop: 100,
        width: '100%',
        overflow:'hidden',
    },

    headtext: {
        color: 'primary',
        fontFamily: 'allerBd',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '5rem',
        },
    },desc:{
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
        },
        color: 'primary',
        fontFamily: 'AllerLt',
        fontWeight: 'bold',
        marginBottom:36,
        marginTop:24,
        marginLeft:24,
        marginRight:24
    },

    paper: {
        margin: 20,
    }
}))


const ProductPage = () => {

    const classes = useStyle();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Grid container direction="column" className={classes.background}>
            <Grid item>
                <Typography align="center" className={classes.headtext}>
                    Products
                        </Typography>
            </Grid>
            <Grid item style={{padding:30}}>
                <Grid container justify="center">
                    <Grid item x={12} md={8}>
                        <Typography align="center" className={classes.desc}>
                        SIMORG INDIA LLP is a full service Import &amp; Export company specializing in agro
products and industrial raw materials with integrated shipping related services.<br/><br/>
We also work as a sourcing agent for our overseas and Indian clients for their raw
materials requirements
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} >
                    {
                        categoriesList.map(obj => (
                            <Grid item xs={12}>
                                <CategoriesListItem image={obj.image} category={obj.category} subcategory={obj.subcategory} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>

    );
}

export default ProductPage;