import { Collapse, Fade, Grid, Hidden, makeStyles, Slide, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import history from '../history';
import MyLink from './MyLink';

const useStyle = makeStyles(theme => ({

    image: {
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
            height: '50vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '50vw',
            height: '25vw'
        },
        backgroundSize: 'cover',

        "&:hover": {
            cursor: 'pointer',

        },
        overflow: 'hidden',
        display: 'flex',
        padding: 0,
        cursor: 'pointer',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
            height: '50vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '50vw',
            height: '25vw'
        },
        backgroundColor: "rgba(37, 37, 37, 0.67)",
        padding: 0,
        margin: 0
    }, whiteContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
            height: '50vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '50vw',
            height: '25vw'
        },
        padding: 0,
        margin: 0
    }, collapse: {
        width: "100%",
        height: "100%",
    }, bigtext: {
        fontSize: 24,
        fontFamily: 'AllerLt',
        color: theme.palette.common.white,
        textDecoration: 'none',
    }, text: {
        fontFamily: 'AllerLt',
        fontSize: 14,
        color: theme.palette.common.white,
        opacity: 0.67
    }, bigtextDark: {
        fontSize: 24,
        fontFamily: 'AllerLt',
        color: "#252525",
        textDecoration: 'none',
    }, textDark: {
        fontFamily: 'AllerLt',
        fontSize: 14,
        color: "#252525",
        opacity: 0.67
    }
}))
const ProductListItem = (props) => {
    const classes = useStyle();

    const [isRevealed, setRevealed] = React.useState(false);

    const handleMouseOver = () => {
        setRevealed(true)
    }

    const handleMouseOut = () => {
        setRevealed(false)
    }

    const handleClick = () => {
        history.push("/products")
        
    }

    return (

        props.image ?
            <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                onClick={handleClick}
                className={classes.image}
                style={{ backgroundImage: `url(${props.image})` }} >
                <Hidden smDown>
                    <Collapse in={isRevealed} className={classes.collapse}>
                        <Grid container className={classes.container}
                            direction="column" justify="center"
                            alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography className={classes.bigtext}>
                                    {props.title}
                                </Typography>
                            </Grid>
                            {/* <Grid item>
                                <Typography className={classes.text}>
                                    {props.description}
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Collapse>
                </Hidden>
                <Hidden mdUp>
                    <Grid container className={classes.container}
                        direction="column" justify="center"
                        alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography align="center" className={classes.bigtext}>
                                {props.title}
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <Typography align="center" className={classes.text}>
                                {props.description}
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Hidden>
            </div>
            :
            <div
                onClick={handleClick}
                className={classes.image}>
                <Grid container className={classes.whiteContainer}
                    direction="column" justify="center"
                    alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography align="center" className={classes.bigtextDark}>
                            {props.title}
                        </Typography>
                    </Grid>
                    {/* <Grid item>
                        <Typography align="center" className={classes.textDark}>
                            {props.description}
                        </Typography>
                    </Grid> */}
                </Grid>
            </div>
    );
}

export default ProductListItem;