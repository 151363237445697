import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Fade from 'react-reveal/Fade';





const useStyle = makeStyles({

    background: {
        backgroundColor: 'rgba(37, 37, 37, 0.67)',
        width: '100%',
    },
    listheadtext: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'AllerLt',
        fontSize: 32,
        padding: '40px 40px 40px 40px',
    },

    listtext: {
        color: 'black',
        fontFamily: 'AllerRg',
        fontSize: 20,
        paddingTop: 5,
        lineHeight: 1,
    },

    image: {
        backgroundSize: 'cover',
    },

    paper: {
        borderRadius:20,
        overflow:'hidden'  
    }
})
const CategoriesListItem = (props) => {

    const classes = useStyle();

    return (

        <Grid container justify='center'>
            <Grid item xs={12} sm={10} md={8} lg={8}>
                <Paper className={classes.paper}>
                    <Grid container direction='column'>
                        <Grid item >
                            <Grid container  className={classes.image} style={{ backgroundImage: `url(${props.image})` }}>
                                <div className={classes.background} >
                                    <Grid item>
                                        <Typography className={classes.listheadtext}>                                          
                                                {props.category}                                           
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item style={{paddingTop:30,paddingBottom:30,overflow:"hidden"}}>
                            <Grid container spacing={1}>
                                {
                                    props.subcategory.map(obj => (
                                        <Grid item xs={12} md={6} >
                                            <ul>
                                                <Typography className={classes.listtext}>
                                                    <Fade bottom>
                                                        <li>{obj}</li>
                                                    </Fade>
                                                </Typography>
                                            </ul>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default CategoriesListItem;
