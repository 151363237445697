import { Box, Fade, Grid, makeStyles, Slide, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import corn from '../photo/corn.jpg';
import coal from '../photo/coal.jpg';
import laboratory from '../photo/laboratory.jpg';
import metal from '../photo/train.jpg';
import ProductListItem from './ProductListItem';

const productsList = [
    { image: corn, title: "Agriculture", description: "Rice, Wheat, Soyabean, Seeds, etc." },
    { image: metal, title: "Minerals & Metals", description: "test1" },
    { image: laboratory, title: "Chemicals", description: "test1" },
    { image: null, title: "View All", description: "test1" }]

const useStyle = makeStyles(theme => ({

    background: {
        backgroundColor: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        overflow: 'hidden'
    },

    headtext: {
        color: '#2f2f2f',
        lineHeight: 1,
        fontFamily: 'allerBd',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '6rem',
        },
    },

    slide: {
        backgroundColor: 'rgba(37, 37, 37, 0.67)',
        height: '100%',
        cursor: 'pointer',
        overflow: 'hidden',
        width: '100%',
        position: 'absolute',
    },desc:{
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 15,
        },
        color: '#212121',
        fontFamily: 'AllerLt',
        fontWeight: 'bold',
        marginBottom:36,
        marginTop:24,
        marginLeft:24,
        marginRight:24
    },

    products: {
        marginTop: 70
    }
}))

const ProductsScreen = () => {

    const classes = useStyle();

    return (
        <section id="productscreen">
            <Grid container direction="column" alignItems="center" justify="space-between" className={classes.background}>
                <Grid item>
                    <Typography className={classes.headtext}>
                        PRODUCTS
                </Typography>
                </Grid>

                <Grid item>
                <Grid container justify="center">
                    <Grid item x={12} md={8}>
                        <Typography align="center" className={classes.desc}>
                        SIMORG INDIA LLP is a full service Import &amp; Export company specializing in agro
products and industrial raw materials with integrated shipping related services.<br/><br/>
We also work as a sourcing agent for our overseas and Indian clients for their raw
materials requirements
                        </Typography>
                    </Grid>
                </Grid>
                    <Grid container className={classes.products}>
                        {
                            productsList.map(obj => (
                                <Grid item>
                                    <ProductListItem image={obj.image} title={obj.title} description={obj.description} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
}

export default ProductsScreen;
