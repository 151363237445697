import { AppBar, Button, Drawer, Grid, Hidden, Icon, IconButton, makeStyles, Slide, Typography, useScrollTrigger } from '@material-ui/core';
import React, { useState } from 'react';
import CallIcon from '@material-ui/icons/Call';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import { ArrowBackRounded } from '@material-ui/icons';
import history from '../history';
import '../call_animation.css';
import { Link, smooth } from 'react-scroll';
import appleIcon from '../photo/appleIcon.png';


const useStyle = makeStyles(theme => ({

    button: {
        cursor: 'pointer',
        borderRadius: "999px",
        marginLeft: 20,
        background: '#8793ca',
        '&:hover': {
            boxShadow: "1px 2px 10px black"
        }
    },

    changebutton: {
        cursor: 'pointer',
        borderRadius: "999px",
        marginLeft: 20,
        background: '#8793ca',
        '&:hover': {
            boxShadow: "1px 2px 10px black"
        }
    },

    text: {
        color: "black",
        padding: "5px 20px 5px 10px",
        fontSize: 15,
        fontWeight: 'bold',
    },

    callicon: {
        color: "#2f2f2f",
        margin: "10px 0px 10px 15px",
        fontSize: 24,
        fontWeight: 'bold',
    },

    links: {
        color: "white",
        fontSize: 15,
        marginLeft: 20,
        opacity: '67%',
        textDecoration: 'none',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            opacity: '100%',
        }
    },

    changelinks: {
        color: "black",
        fontSize: 15,
        marginLeft: 20,
        opacity: '67%',
        textDecoration: 'none',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            color: 'black',
            opacity: '100%',
        }
    },

    appbaar: {
        background: 'transparent',
        boxShadow: 'none',
    },

    changeappbar: {
        background: "#f5f5f5",
    },

    logotext: {
        color: 'white',
        fontFamily: 'TimesNewRoman',
        marginLeft: 5,
        fontSize: 22,
        fontWeight: 'bold',
        cursor: 'pointer',
    },

    changelogotext: {
        color: 'black',
        fontFamily: 'TimesNewRoman',
        marginLeft: 5,
        fontSize: 22,
        fontWeight: 'bold',
        cursor: 'pointer',
    },

    menuicon: {
        color: 'white',
        fontWeight: 'bold'
    },

    changemenuicon: {
        color: 'black',
        fontWeight: 'bold'
    },

    calllogo: {
        borderRadius: 50,
        color: '#2f2f2f',
        fontWeight: 'bold',
        background: '#8793ca',
        width: 50,
        height: 50,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: "1px 2px 10px black",
        }
    },

    changecalllogo: {
        borderRadius: 50,
        color: '#2f2f2f',
        fontWeight: 'bold',
        background: '#8793ca',
        width: 50,
        height: 50,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: "1px 2px 10px black",
        }
    },

    textllp: {
        marginLeft: 5,
        fontSize: 22,
        color: 'white',
        cursor: 'pointer',
        fontFamily:'AllerRg',
    },

    changetextllp: {
        marginLeft: 5,
        fontSize: 22,
        color: 'black',
        cursor: 'pointer',
    },
    drawer: {
        zIndex: theme.zIndex.drawer,
    }, menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: "100vw",
        backgroundColor: "#252525"
    },
    drawerContainer: {
        overflow: 'hidden',
        color: theme.palette.common.white
    },
    drawerIcon: {
        color: theme.palette.common.white,
        width: 24,
        height: 24
    },
    drawerText: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 8,
        color: theme.palette.common.white,
        cursor: 'pointer'
    },
    logo:{
        marginRight:20,
        width:44,
        height:44
    }
}))




const MyAppbar = (props) => {


    const classes = useStyle();

    const [appbar, setAppbar] = useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    React.useEffect(()=>{
        if(window.location.pathname === "/products")
            setAppbar(true);
    },[window.location.pathname])

    const changeAppbar = () => {
        if (window.scrollY >= 10 || window.location.pathname === "/products") {
            setAppbar(true);
        } else {
            setAppbar(false);
        }
    }
    window.addEventListener('scroll', changeAppbar);

    function HideOnScroll(props) {

        const { children, window } = props;
        const trigger = useScrollTrigger({ target: window ? window() : undefined });

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }

    HideOnScroll.propTypes = {
        children: PropTypes.element.isRequired,
        window: PropTypes.func,
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const handleAboutUs = () => {
        if (props.product)
            history.push("/")
        setOpenDrawer(false);
    }

    const handleServices = () => {
        if (props.product)
            history.push("/")
        setOpenDrawer(false);
    }

    const handleProducts = () => {
        if (props.product)
            history.push("/")
        setOpenDrawer(false);
    }

    const handleHomeClick = () => {
        history.push("/")
    }

    function getDrawer() {
        return (
            <div className={classes.drawerContainer} role="presentation">
                <Grid container justify="flex-start">
                    <Grid item style={{ paddingTop: 24, paddingRight: 24 }}>
                        <IconButton onClick={handleDrawerClose}><ArrowBackRounded className={classes.drawerIcon} /></IconButton>
                    </Grid>
                </Grid>

                <Grid container spacing={3} direction="column" justify="center" alignItems="center" style={{ height: "80vh" }}>
                    <Grid item style={{ width: "100%" }} >
                        {
                            props.product ?
                                <Grid container justify="center" alignItems="center" onClick={handleAboutUs}>
                                    <Grid item>
                                        <Typography className={classes.drawerText}>ABOUT US</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Link to="aboutus" smooth={true} duration={1000}>
                                    <Grid container justify="center" alignItems="center" onClick={handleAboutUs}>
                                        <Grid item>
                                            <Typography className={classes.drawerText}>ABOUT US</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                        }
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                        {
                            props.product ?
                                <Grid container justify="center" alignItems="center" onClick={handleServices}>
                                    <Grid item>
                                        <Typography className={classes.drawerText}>SERVICES</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Link to="services" smooth={true} duration={1000}>
                                    <Grid container justify="center" alignItems="center" onClick={handleServices}>
                                        <Grid item>
                                            <Typography className={classes.drawerText}>SERVICES</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                        }
                    </Grid>
                    <Grid item style={{ width: "100%" }} >
                        {
                            props.product ?
                                <Grid container justify="center" alignItems="center" onClick={handleProducts}>
                                    <Grid item>
                                        <Typography className={classes.drawerText}>PRODUCTS</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Link to="productscreen" smooth={true} duration={1000}>
                                    <Grid container justify="center" alignItems="center" onClick={handleProducts}>
                                        <Grid item>
                                            <Typography className={classes.drawerText}>PRODUCTS</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                        }

                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <Grid container direction="column" justify='center'>
            {
                openDrawer ?
                    <Hidden mdUp implementation="css">
                        <nav className={classes.drawer} aria-label="drawer menus">
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                            <Drawer
                                // container={container}
                                variant="temporary"
                                anchor={'left'}
                                open={openDrawer}
                                onClose={handleDrawerClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {getDrawer()}
                            </Drawer>
                        </nav>
                    </Hidden>
                    :
                    null
            }
            <Grid item>
                <HideOnScroll {...props}>
                    <AppBar position="fixed" style={{ padding: 16, height: 80 }} alignItems="center" className={appbar ? classes.changeappbar : classes.appbaar} >
                        <Grid container justify="space-between" alignItems="center" align="center">
                            <Grid item >
                                <Grid container justify="space-between" align="center" alignItems="center">
                                    <Hidden mdUp>
                                        <Grid item>
                                            <IconButton onClick={handleDrawerOpen} className={appbar ? classes.changemenuicon : classes.menuicon}>
                                                <MenuIcon />
                                            </IconButton>
                                        </Grid>
                                    </Hidden>
                                    <Grid item>
                                        <img src={appleIcon} className={classes.logo}/>
                                    </Grid>
                                    <Hidden xsDown>
                                    <Grid item>
                                        <Typography onClick={handleHomeClick} className={appbar ? classes.changelogotext : classes.logotext}>
                                            SIMORG INDIA LLP
                                        </Typography>
                                    </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container justify="space-between" align="center" alignItems="center">
                                    <Hidden smDown>
                                        {
                                            props.product ?
                                                <Grid item onClick={handleProducts}>
                                                    <Typography className={appbar ? classes.changelinks : classes.links}>
                                                        ABOUT US
                                            </Typography>
                                                </Grid>
                                                :
                                                <Grid item >
                                                    <Link to="aboutus" smooth={true} duration={1000}>
                                                        <Typography className={appbar ? classes.changelinks : classes.links}>
                                                            ABOUT US
                                            </Typography>
                                                    </Link>
                                                </Grid>
                                        }
                                    </Hidden>
                                    <Hidden smDown>
                                        {
                                            props.product ?
                                                <Grid item onClick={handleProducts}>
                                                    <Typography className={appbar ? classes.changelinks : classes.links} >
                                                        SERVICES
                                            </Typography>
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Link to="services" smooth={true} duration={1000}>
                                                        <Typography className={appbar ? classes.changelinks : classes.links} >
                                                            SERVICES
                                            </Typography>
                                                    </Link>
                                                </Grid>
                                        }
                                    </Hidden>

                                    <Hidden smDown>
                                        {
                                            props.product ?
                                                <Grid item onClick={handleProducts}>
                                                    <Typography className={appbar ? classes.changelinks : classes.links}>
                                                        PRODUCTS
                                                </Typography>
                                                </Grid>
                                                :
                                                <Grid item >
                                                    <Link to="productscreen" smooth={true} duration={1000}>
                                                        <Typography className={appbar ? classes.changelinks : classes.links}>
                                                            PRODUCTS
                                                </Typography>
                                                    </Link>
                                                </Grid>
                                        }
                                    </Hidden>

                                    <Hidden smDown>
                                        {
                                            props.product ?
                                                <Grid item onClick={handleProducts}>
                                                    <Grid className={appbar ? classes.changebutton : classes.button}>
                                                        <Grid container justify="space-between" align="center" alignItems="center" >
                                                            <Grid item>
                                                                <CallIcon id="phone-animation" className={classes.callicon} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.text}>
                                                                    Contact NOW
                                                        </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Link to="contactus" smooth={true} duration={1000}>
                                                        <Grid className={appbar ? classes.changebutton : classes.button}>
                                                            <Grid container justify="space-between" align="center" alignItems="center" >
                                                                <Grid item>
                                                                    <CallIcon id="phone-animation" className={classes.callicon} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography className={classes.text}>
                                                                        Contact NOW
                                                        </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Link>
                                                </Grid>
                                        }

                                    </Hidden>

                                    <Hidden mdUp>
                                        <Grid item >
                                            <Link to="contactus" smooth={true} duration={1000}>
                                                <Grid container justify="center" alignItems="center" className={appbar ? classes.changecalllogo : classes.calllogo}>
                                                    <Grid item >
                                                        <CallIcon id="phone-animation" size="large" />
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AppBar>
                </HideOnScroll>
            </Grid>
        </Grid>
    );
}

export default MyAppbar;