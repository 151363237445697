import MyAppbar from './components/MyAppbar';
import HomeScreen from './components/HomeScreen';
import Footer from './components/Footer';
import ProductPage from './components/ProductPage';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';
import { connectToServer } from './utils/ParseServer';
import React from 'react';

function App() {
  const [path,setPath] = React.useState("");
  React.useEffect(() => {
    connectToServer()
    setPath(window.location.pathname)
    history.listen((location, action) => {
      // location is an object like window.location
      setPath(location.pathname);
    });

  })

  return (
    <div className="App">
      <Router history={history}>
        <MyAppbar product={path==="/products"} />
        <Switch>
          <Route path="/products" component={ProductPage} />
          <Route component={HomeScreen} />
        </Switch>
        <Footer product={path==="/products"}/>
      </Router>
    </div>

  );
}

export default App;
