import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import nightCity from '../photo/nightCity.jpg';
import Fade from 'react-reveal/Fade'

const useStyle = makeStyles(theme => ({

    parent: {
        backgroundColor: 'white',
        minHeight: '100vh',
        width: '100%',
        [theme.breakpoints.up("md")]: {
            padding: 75,
        }, [theme.breakpoints.down("sm")]: {
            padding: 40,
        },
    },
    background: {
        width: '100%',
        height: '100%',
    },

    image: {
        backgroundImage: `url(${nightCity})`,
        minWidth: '100%',
        minHeight: '100%',
        backgroundSize: 'cover'
    },

    imagetext: {
        height: '100%',
        width: '100%',

    },

    text: {
        color: "white",
        fontFamily: 'allerBd',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem',
        },
    },

    box: {
        backgroundColor: "rgba(0,0,0,0.5)",
        padding: 24,
        margin: 24,
        [theme.breakpoints.down('sm')]: {
            padding: 24,
        },
        [theme.breakpoints.up('md')]: {
            padding: 48,
        },
    },

    paragraph: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'AllerRg',
    }

}))

const AboutUs = () => {

    const classes = useStyle();

    return (
        <section id="aboutus">
            <Grid container className={classes.parent} justify="center">
                <Grid item className={classes.image} xs={12} sm={10} md={6} lg={4}>
                    <div className={classes.background}>
                        <Grid container className={classes.imagetext} alignItems='center' justify="center">
                            <Grid item xs={12} sm={10} md={6} lg={4}>
                                <Typography align="center" fullWidth variant="h2" className={classes.text} >
                                    About Us
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={6} lg={8} >
                                <Fade bottom>
                                    <div className={classes.box}>
                                        <Typography align="center" className={classes.paragraph}>
                                        Our aim is to make sure that we provide our clients very good quality products that
meet the standard requirements of quality and quantity in time and also want to
make sure our clients are satisfied in terms of value and delivery time.
<br/><br/>
Best-in-class quality is something we aim for every day.
<br/><br/>
Minimization of costs and time spent at each stage is our priority task, solving it, we
help our client to receive the goods at the planned time and at the current market
price.
<br/><br/>
Being active at every stage of supply chain from local procurement, extensive
logistics, export operations to deliver, import, storage, distribution, we maximize
value for our trade partners
<br/><br/>
We are committed to build a long-term successful and reliable partnership, as well
as to mutually beneficial cooperation with our customers.
                                
                                    </Typography>
                                    </div>
                                </Fade>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default AboutUs;