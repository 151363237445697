import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: "inherit"
    }
}))

const MyLink = (props) => {
    const classes = useStyles();
    return (
        <div>
            {props.disabled ?
                props.children
                :
                <Link to={props.to} className={classes.link}>
                    {props.children}
                </Link>
            }
        </div>

    );
}

export default MyLink;